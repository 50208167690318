<template>
  <div class="flex items-center justify-center h-10 mb-4 rounded bg-white">
    <p class="text-2xl text-gray-400 dark:text-black typingEffect">
      About Me
    </p>
  </div>
    <div class="flex items-center justify-center h-48 mb-4 rounded bg-white">
      <code>
        <p class="text-2xl text-gray-400 dark:text-black typingEffect">
          Hello im Van Pen
        </p>
      </code>
    </div>
    <div class="mb-3 grid grid-cols-2 gap-4">
      <div class="flex items-center justify-center h-50 bg-gray-50 dark:bg-gray-800">
        <p class="text-2xl text-gray-400 dark:text-gray-500 p-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      <div class="flex items-center justify-center h-50 bg-gray-50 dark:bg-gray-800">
        <p class="text-2xl text-gray-400 dark:text-gray-500 p-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      <div class="flex items-center justify-center h-50 bg-gray-50 dark:bg-gray-800">
        <p class="text-2xl text-gray-400 dark:text-gray-500 p-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      <div class="flex items-center justify-center h-50 bg-gray-50 dark:bg-gray-800">
        <p class="text-2xl text-gray-400 dark:text-gray-500 p-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
    </div>

</template>

<script>
export default {
  name: 'ScrollSection',
  methods: {

  }

};
</script>

<style scoped>
code {
  display: flex;
  margin: 0 auto;
}
.scrollsection {
/* Hauteur fixe pour la zone de défilement */

  height: 1400px;
}

.blocktext {
  text-align: center;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  background-color: #e5b05e;
  border-radius: 5px;
}

.typingEffect {
  width: 0;
  overflow: hidden; /* Ensure the text is not visible until the typewriter effect*/
  border-right: 2px solid white; /* The cursor*/
  font-family: 'New Super Mario Font U', sans-serif;
  text-align: center;
  font-size: 30px;
  white-space: nowrap; /* Keeps the text on a single line */
  animation: typing 2s steps(30) forwards;
}

/* The typing animation */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
</style>