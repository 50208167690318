<template>
  <div class="container mx-auto bg-gray-50 dark:bg-gray-800 rounded-lg">
    <div class="mx-auto text-gray-400 dark:text-gray-500 rounded-lg overflow-hidden shadow-lg">
      <div class="p-4">
        <h2 class="text-2xl font-semibold text-white mb-2">Contactez-nous</h2>
        <p class="text-gray-600 mb-6">Remplissez le formulaire ci-dessous pour nous contacter.</p>
        <form action="#" method="POST">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Nom</label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Votre nom">
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Votre email">
          </div>
          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="message">Message</label>
            <textarea class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" rows="5" placeholder="Votre message"></textarea>
          </div>
          <div class="flex items-center justify-between">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      message: ''
    };
  },
  methods: {
    submitForm() {
      // Validation du formulaire
      if (!this.formData.name || !this.formData.email || !this.formData.message) {
        this.message = "Veuillez remplir tous les champs.";
        return;
      }

      // Envoi du formulaire (simulé pour cet exemple)
      setTimeout(() => {
        // Réinitialisation du formulaire
        this.formData.name = '';
        this.formData.email = '';
        this.formData.message = '';

        // Message de confirmation
        this.message = "Votre message a été envoyé avec succès !";
      }, 1000);
    }
  }
};
</script>


<style scoped>

</style>